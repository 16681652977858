<template>
  <div class="pcadd">
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-menu"></i> 客户端弹窗
          <i class="el-icon-arrow-right"></i>
          {{$route.name =='pcAdd' ?'添加':'修改'}}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <el-form ref="formData" label-width="140px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="客户端类型：" required>
              <el-select v-model="clientId" placeholder="请选择" class="handle-select mr20">
                <el-option
                  :key="item.id"
                  v-for="(item) in channelDatas"
                  :label="item.description"
                  :value="item.clientId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="弹窗标题：" required>
              <el-input v-model="title" placeholder="请输入标题" maxlength='30'></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="配图：" required>
          <div class="file">
            选择文件
            <input type="file" name id />
          </div>
        </el-form-item>
        <el-row>
          <el-col :span="6">
            <el-form-item label="推送国家：" required>
              <el-select v-model="country" multiple placeholder="请选择" @change="countryChange">
                <el-option
                  v-for="(item) in  countryFullList"
                  :key="item.country_id"
                  :label="item.country_name_cn"
                  :value="item.country_name_en"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="用户身份：" required>
              <el-select v-model="user" placeholder="请选择" class="handle-select mr20">
                <el-option
                  :key="item.id"
                  v-for="(item) in userlevelArr"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="推送时间：" required>
          <el-date-picker
            v-model="times"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="每日弹出时间点：" required>
          <el-time-picker v-model="date1" placeholder="选择日期" class="mr20"></el-time-picker>
          <el-time-picker v-model="date2" placeholder="选择日期" class="mr20"></el-time-picker>
          <el-time-picker v-model="date3" placeholder="选择日期" class="mr20"></el-time-picker>
          <el-time-picker v-model="date4" placeholder="选择日期" class="mr20"></el-time-picker>
          <el-time-picker v-model="date5" placeholder="选择日期" class="mr20"></el-time-picker>
        </el-form-item>
        <el-form-item label="用户操作行为：" required>
          <el-select v-model="operation" placeholder="请选择" class="handle-select mr20">
            <el-option
              :key="item.id"
              v-for="(item) in operationArr"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="6">
            <el-form-item label="跳转链接：" required>
              <el-input v-model="sourceUrl" placeholder="请输入网址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button type="primary" icon="el-icon-s-promotion" @click="pushFn">发布</el-button>
    </div>
  </div>
</template>

<script>
import {
  clientConfig

  //   seoLabelUpdate,
  //   seoLabelBatch,
  //   seoLabelList,
  //   seoLabelOption
} from "../../api/index";
import { countryFullList } from "../../assets/js/common";
const all = {
  country_name_cn: "全球",
  country_name_en: "",
  country_id: 999999
};
export default {
  name: "pcAdd",
  data() {
    return {
      sourceUrl: "",
      operation: "",
      operationArr: [
        {
          id: 1,
          name: "每次打开"
        },
        {
          id: 1,
          name: "首次打开"
        },
        {
          id: 3,
          name: "打开3次"
        },
        {
          id: 4,
          name: "打开5次"
        },
        {
          id: 5,
          name: "打开8次"
        }
      ],
      date1: "",
      date2: "",
      date3: "",
      date4: "",
      date5: "",
      userlevelArr: [
        {
          id: 1,
          name: "全部用户"
        },
        {
          id: 3,
          name: "付费用户"
        },
        {
          id: 2,
          name: "免费用户"
        }
      ],
      user: "",
      times: "",
      country: [],
      countryTag: [],
      clientId: "",
      channelDatas: [],
      title: "",
      countryFullList: [all, ...countryFullList],
      dialogTitle: "添加标签"
    };
  },
  created() {
    this.channelArray(); // 渠道
  },
  methods: {
    pushFn() {},
    countryChange(e) {
      // 全球单选，其他多选
      const eHasGlobal = e.filter(res => {
        // 新选的是否包含全球
        return res == "";
      });
      const oddHasGlobal = this.countryTag.filter(res => {
        // 旧选的是否包含全球
        return res == "";
      });

      if (oddHasGlobal.length > 0 && e.length > 1) {
        console.log(11111);
        this.country = e.filter(res => {
          // 新选的是否包含全球
          return res != "";
        });
      }
      if (oddHasGlobal.length == 0 && eHasGlobal.length > 0) {
        this.country = [""];
      }
      this.countryTag = this.country;
    },
    channelArray() {
      clientConfig().then(res => {
        if (res.code == 0) {
          this.channelDatas = res.data;
          this.channelDatas.unshift({
            description: "全部",
            clientId: ""
          });
        }
        console.log("res", res);
      });
    }
  }
};
</script>

<style lang='scss'>
.pcadd {
  .file {
    margin-top: 6px;
    position: relative;
    display: inline-block;
    background: #d0eeff;
    border: 1px solid #99d3f5;
    border-radius: 4px;
    padding: 4px 12px;
    overflow: hidden;
    color: #1e88c7;
    text-decoration: none;
    text-indent: 0;
    line-height: 20px;
  }
  .file input {
    position: absolute;
    font-size: 100px;
    right: 0;
    top: 0;
    opacity: 0;
  }
  .file:hover {
    background: #aadffd;
    border-color: #78c3f3;
    color: #004974;
    text-decoration: none;
  }
  .handle-box {
    margin-bottom: 20px;
  }

  .handle-select {
    width: 180px;
  }

  .handle-input {
    width: 300px;
    display: inline-block;
  }
  .table {
    width: 100%;
    font-size: 14px;
  }
  .red {
    color: #ff0000;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mr20 {
    margin-right: 20px;
  }
  .table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
  }
  .w80 {
    width: 98px;
  }
  .ml10 {
    margin-left: 10px;
  }
  .btns {
    margin-bottom: 10px;
  }
  .check {
    display: flex;
  }
}
</style>
